@import "./reset.css";

@font-face {
  font-family: "Oswald";
  src: url(../fonts/Oswald-Regular.ttf);
}

@font-face {
  font-family: "Permanent Marker";
  src: url(../fonts/PermanentMarker-Regular.ttf);
}
:root {
  --main-color-dark: rgb(18, 18, 18);
  --body-color-dark: rgb(27, 26, 27);
  --accessory-color-dark: rgb(35, 34, 36);
  --text-color-light: rgb(236, 195, 236);
  --accent_color: rgb(176, 208, 238);

  --video-height: 40vw;
  --video-width: 65vw;
  --video-height-adapt: calc(100vh * 0.3);

  --img-height: 40vh;
  --img-height-adapt: 30vh;
}
html,
body {
  font-family: "Oswald", sans-serif;
  font-weight: 400;
  height: 100vh;
  background-color: var(--body-color-dark);
  color: var(--text-color-light);
}

/* common container  */
.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 30px;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.start_page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.start_video {
  position: relative;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  object-fit: cover;
}

.start_video-mobile {
  display: none;
}

.start_content {
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.start_image {
  display: none;
}

.logo {
  width: 200px;
  opacity: 10%;
  transition: 700ms;
}

.logo:hover {
  opacity: 80%;
}
/* Home page */
.story-hefu {
  margin-bottom: 50px;
  box-shadow: 0px 7px 58px 5px rgba(0, 0, 0, 0.75);
  margin-bottom: 90px;
}

.title {
  font-family: "Permanent Marker", sans-serif;
  font-size: 8vh;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-shadow: 0 -1px 0 #000000, 0 1px 0 #000000, 0 2px 0 #2c2c2c,
    0 3px 0 #2a2a2a, 0 4px 0 #282828, 0 5px 0 #262626, 0 6px 0 #242424,
    0 7px 0 #222, 0 8px 0 #202020, 0 9px 0 #1e1e1e, 0 10px 0 #1c1c1c,
    0 11px 0 #1a1a1a, 0 12px 0 #181818, 0 13px 0 #161616, 0 14px 0 #141414,
    0 15px 0 #121212, 0 22px 30px rgba(0, 0, 0, 0.9);

  text-align: center;

  margin-bottom: 50px;
}

p {
  letter-spacing: 0.7px;
}

/* Artists page */
.artist-list {
  display: flex;
  column-gap: 200px;
  flex-wrap: wrap;

  justify-content: center;
}

.section {
  height: 100%;
  margin: auto;
}

.artists-container {
  padding: 0 20px;
}

/* Artist page */

.artist-page {
  height: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  text-align: center;
}

.artist-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 50px;
  row-gap: 50px;

  flex-wrap: wrap;

  padding: 0 30px;
  margin-bottom: 50px;
}
.artist-cover {
  height: 100%;
  border-radius: 20px;

  box-shadow: 0px 7px 58px 5px rgba(0, 0, 0, 0.75);
}

.artist-cover-container {
  max-width: 400px;
}

.artist-cover__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  row-gap: 10px;
}

.genres {
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.music-links {
  padding: 5px;

  color: var(--text-color-light);

  box-shadow: 0px 5px 5px -5px rgba(238, 217, 227, 0.171);
}

/* Mobile adaptation */
@media (max-width: 600px) {
  .start_video {
    display: none;
  }

  .start_video-mobile {
    display: flex;
    position: relative;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  .logo {
    width: 150px;
    opacity: 30%;
  }

  .title {
    font-size: 5vh;
    margin-bottom: 25px;
  }

  .artist-cover {
    height: 30vh;
  }

  .story-hefu {
    margin-bottom: 20px;
  }

  .content__youtube {
    margin-bottom: 20px;
  }

  .artist-list {
    column-gap: 100px;
  }

  .artist-info {
    margin-bottom: 30px;
  }
}

/* spotifi widget adapt */
@media (max-width: 878px) {
  iframe {
    height: 152px;
  }
}
