.nav {
  padding: 0 5px;
  background-color: var(--main-color-dark);
  border-bottom: 1px solid var(--accessory-color-dark);
}

.header__nav-list {
  display: flex;
  text-align: center;
  align-items: center;
}

.nav-row {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.logo-link {
  margin-right: auto;
}

.home-logo {
  width: 70px;
}

.nav-list {
  display: flex;
  column-gap: 20px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.nav-list__item {
  font-size: 1em;
  color: var(--text-color-light);
}

.nav-list__link--active {
  position: relative;
}

.nav-list__link--active::before {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  top: 100%;
  left: 0;
  background-color: var(--accent_color);
}
/* Burger menu */
.header__menu-icon {
  display: none;
}

@media (max-width: 600px) {
  .header__menu-icon {
    display: block;
  }
  .header__menu-icon--pic {
    height: 40px;
  }
  .header__nav-list {
    position: fixed;
    top: 0;
    left: -225px;
    width: 220px;
    height: 100%;

    display: flex;
    flex-direction: column;

    background-color: var(--main-color-dark);
    border-right: 1px solid var(--accessory-color-dark);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 0 40px 4px rgba(12, 12, 12, 0.123);

    transition: transform 0.3s linear;
  }

  .header__nav-list.active {
    transform: translateX(100%);
  }

  .nav-list {
    padding-top: 60px;
    flex-direction: column;
  }

  .nav-list__item {
    margin-bottom: 20px;
  }
  .nav-list__link--active::before {
    top: 25px;
  }
}
