.container__content {
  max-width: 1000px;

  margin: 0 auto;
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  row-gap: 70px;
}

.content {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 40px;
  justify-content: center;

  padding-bottom: 30px;
  border-bottom: 1px solid rgb(44, 43, 43);
}

.content:last-child {
  border-bottom: none;
  margin-bottom: 50px;
}

.content-text {
  display: flex;
  max-width: 300px;
  text-align: center;
  align-items: center;
}

.content-img {
  height: var(--img-height);
  border-radius: 10px;
  box-shadow: 0px 7px 28px 5px rgba(0, 0, 0, 0.75);
}

@media (max-width: 600px) {
  .content-img {
    height: var(--img-height-adapt);
  }
}
