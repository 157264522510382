.artist {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  row-gap: 15px;

  padding: 15px;
  margin-bottom: 20px;
}

.artist__logo {
  height: 300px;
  border-radius: 150px;

  box-shadow: 0px 7px 28px 5px rgba(0, 0, 0, 0.75);
  margin-bottom: 15px;
}

.artist__name {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 1px;
}

.artist__link {
  color: var(----text-color-light);
}

@media (max-width: 600px) {
  .artist__logo {
    height: 250px;
  }
  h1 {
    margin-top: 30px;
  }
}
